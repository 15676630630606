<template>
  <div>
    <app-card>
      <template #body>
        <fire-zone-form :block-id="blockId" :zone="zone" @change="form = $event"/>
      </template>
    </app-card>

    <portal to="subheader-toolbar">
      <b-button class="mr-3" variant="transparent-white" @click="$router.push({name: 'showBlock', params: {id: blockId}, hash: hash})">
        {{ $t('btn.back_to_block') }}
      </b-button>
      <b-button class="mr-3" variant="danger" v-b-modal.delete-block-modal>
        {{ $t('btn.delete') }}
      </b-button>
      <b-button variant="success" @click="updateItem">
        {{ $t('btn.update') }}
      </b-button>
    </portal>

    <app-modal
        id="delete-block-modal"
        :title="$t('label.confirm_action')"
        :ok-text="$t('btn.delete')"
        ok-variant="danger"
        @ok="deleteItem"
    >
      <template #app-modal-body>
        <span class="font-size-lg">{{ $t('modal.delete_fire_zone') }}?</span>
      </template>
    </app-modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AppCard from '@/components/AppCard';
import AppModal from '@/components/modals/AppModal';
import FireZoneForm from './components/FireZoneForm';

export default {
  components: {
    AppCard,
    AppModal,
    FireZoneForm
  },
  data: () => ({
    blockId: null,
    form: {},
    hash: '#fire_zones'
  }),
  beforeMount() {
    this.$store.dispatch('fireZonesStore/GET_ZONE', this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      zone: 'fireZonesStore/ZONE'
    })
  },
  watch: {
    zone(data) {
      this.blockId = data.block.id;
    }
  },
  methods: {
    updateItem() {
      this.$store.dispatch('fireZonesStore/UPDATE', {id: this.zone.id, data: this.form}).then(() => {
        this.$router.push({name: 'showBlock', params: {id: this.blockId}, hash: this.hash});
      });
    },
    deleteItem() {
      this.$store.dispatch('fireZonesStore/DELETE', this.zone.id).then(() => {
        this.$router.push({name: 'showBlock', params: {id: this.blockId}, hash: this.hash});
      });
    }
  }
}
</script>